<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitData"
          >
            <v-card-text>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Participant Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ParticipantName"
                      :rules="ParticipantNameRules"
                      placeholder="Participant Name"
                      hint="Enter participant name"
                      persistent-hint
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      <span class="text-danger">*</span> Gender
                    </h6>
                    <v-select
                      v-model="Gender"
                      :items="GenderOptions"
                      :rules="GenderRules"
                      :reduce="(option) => option.value"
                      hint="Select gender from the list"
                      persistent-hint
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Age
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Age"
                      :rules="AgeRules"
                      placeholder="Age"
                      v-mask="'##'"
                      hint="Enter participant age"
                      persistent-hint
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Email ID
                      </h6>
                    </label>
                    <v-text-field
                      v-model="EmailId"
                      :rules="EmailIdRules"
                      placeholder="Email ID"
                      hint="Enter participant email id"
                      persistent-hint
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Mobile Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MobileNumber"
                      :rules="MobileNumberRules"
                      :counter="10"
                      v-mask="'##########'"
                      placeholder="Mobile number"
                      hint="Enter participant mobile number"
                      persistent-hint
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitData"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,

      LoadingFlag: false,
      SubmitFlag: false,

      ParticipantName: "",
      ParticipantNameRules: [(v) => !!v || "Participant name is required"],

      Gender: "",
      GenderRules: [(v) => !!v || "Gender is required"],
      GenderOptions: [
        { value: "", text: "--Select--" },
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
        { value: "Others", text: "Others" },
      ],

      Age: "",
      AgeRules: [(v) => !!v || "Age is required"],

      MobileNumber: "",
      MobileNumberRules: [(v) => !!v || "Mobile number is required"],

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "E-mail ID is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail ID must be valid",
      ],

      Member: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt(Member = {}) {
      console.log("closePrompt called");
      console.log({ Member });
      this.$emit("hideDialog", Member);
    },
    refreshPageData() {
      console.log("refreshPageData called");
    },
    submitData() {
      console.log("submitData is called");
      var message = "";
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        var tempData = {
          MemberFlag: true,
          ParticipantName: this.ParticipantName,
          Gender: this.Gender,
          Age: this.Age,
          MobileNo: this.MobileNumber,
          EmailId: this.EmailId,
          action: "",
        };
        console.log({ tempData });
        this.closePrompt(tempData);
      } else {
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message, true);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-image {
  .mycard-text1 {
    font-family: sans-serif;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
}
</style>